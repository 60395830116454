var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

var site = site || {};

site.template = site.template || {};

(function ($) {
  /**
   * In response to clicking the overlay launch link, find the closest product
   * Uses the supplied .js-product to load the proper product data.
   * Then fires up the product_personalization_overlay template.
   */
  $(document).on('click', '.js-personalization-launch-overlay', function (e) {
    e.preventDefault();
    if ($(this).data('disabled')) {
      return;
    }

    // Get sku selected for add to bag
    var $addBtn = $('.js-add-to-cart', '.js-product');
    var skuBaseId = $addBtn.data('sku-base-id');
    var data = {
      SKU_BASE_ID: skuBaseId
    };

    $(this).closest('.js-product').trigger('product.personalization.overlay', data);
  });

  $(document).on('product.updateInvStatus', '.js-product', function () {
    var $personalizationBtn = $('.js-personalization-launch-overlay', this);
    var $addBtn = $('.js-add-to-cart', this);
    var skuBaseId = $addBtn.data('sku-base-id');
    var skuDataL2 = prodcat.data.getSku(skuBaseId);

    if (!skuBaseId) {
      $personalizationBtn.addClass('button--disabled').data('disabled', true);
    } else if (skuBaseId && skuDataL2) {
      if (skuDataL2.isShoppable) {
        $personalizationBtn.removeClass('button--disabled').data('disabled', false);
      } else {
        $personalizationBtn.addClass('button--disabled').data('disabled', true);
      }
    }
  });
})(jQuery);
